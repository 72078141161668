import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LocationVisits } from '../../component/site/LocationVisits';
import useTablePagination from '../../hooks/useTablePagination';
import { getLocationVisits, getLocationVisitsById } from '../../redux/slices/locationVisits/locationVisitsSlice';
import { useDispatch } from '../../redux/store';

function LocationVisitsTab({ withStockInstalled }: { withStockInstalled?: boolean }): ReactElement {
  const dispatch = useDispatch();
  const { id } = useParams();
  const locationVisits = useSelector(getLocationVisitsById({ itemId: id }));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: locationVisits?.pagination });

  useEffect(() => {
    if (id) {
      dispatch(getLocationVisits({ itemId: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  return (
    <LocationVisits
      items={locationVisits}
      paginationControl={paginationControl}
      pagination={pagination}
      dense
      noUsed
      noLeftBehind
      withStockInstalled={withStockInstalled}
    />
  );
}

export default LocationVisitsTab;
