import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { assignmentsClient } from '../../../api/client';
import { AssignmentCreateRequest, AssignmentRequestUpdate, QueryAssignment, QueryAssignmentKPIs } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { RootState } from '../../store';
import { AssignmentsListType, AssignmentsState, AssignmentsTypeMapped, ListAssignmentsQuery } from './assignmentsInterface';

export const assignmentsAdapter = createEntityAdapter<AssignmentsListType>({
  selectId: (assignment) => assignment.id || '',
});

export const jobsiteAssignmentAdapter = createEntityAdapter<QueryAssignment>({
  selectId: (assignment) => assignment.job_site_id || '',
});

export const assignmentAdapter = createEntityAdapter<QueryAssignment>({
  selectId: (assignment) => assignment.id || '',
});

export const getJobsiteAssignment = createAsyncThunk('assignments/getJobsiteAssignment', async ({ siteId, options }: { siteId: string; options?: any }) =>
  assignmentsClient.getJobsiteAssignment(siteId, options),
);

export const getAssignmentById = createAsyncThunk('assignments/getAssignmentById', async (assigmentId: string) =>
  assignmentsClient.getAssignmentById(assigmentId),
);

export const createAssignment = createAsyncThunk('assignments/createAssignment', async (payload: AssignmentCreateRequest) =>
  assignmentsClient.createAssignment(payload),
);
export const lookupAssignmentItem = createAsyncThunk(
  'assignments/lookupAssignmentItem',
  async ({ assignmentId, identifier }: { assignmentId: string; identifier: string }) => assignmentsClient.lookupAssignmentItem(assignmentId, identifier),
);
export const updateAssignment = createAsyncThunk('assignments/updateAssignment', async (payload: AssignmentRequestUpdate) =>
  assignmentsClient.updateAssignment(payload),
);

export const deliverAssignment = createAsyncThunk('assignments/deliverAssignment', async (assignmentId: string) =>
  assignmentsClient.deliverAssignment(assignmentId),
);

export const deleteAssignment = createAsyncThunk('assignments/deleteAssignment', async (assignmentId: string) =>
  assignmentsClient.deleteAssignment(assignmentId),
);

export const listAssignments = createAsyncThunk('assignments/listAssignments', async (query: ListAssignmentsQuery) => {
  const argumentsKeys = ['delivered', 'deliveredBegin', 'deliveredEnd', 'sortBy', 'offset', 'limit', 'options'];
  return assignmentsClient.listAssignments.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [
      delivered?: boolean,
      deliveredBegin?: string,
      deliveredEnd?: string,
      sortBy?: string,
      offset?: number,
      limit?: number,
      options?: Record<string, unknown>,
    ],
  );
});

export const getAssignmentKpis = createAsyncThunk('assignments/getAssignmentKpis', async () => assignmentsClient.getAssignmentKpis());

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    assignments: assignmentsAdapter.getInitialState(),
    jobsiteAssignment: jobsiteAssignmentAdapter.getInitialState(),
    assignment: assignmentAdapter.getInitialState(),
    kpis: {},
  } as AssignmentsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAssignments.pending, (state: AssignmentsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), listAssignments: true };
    });
    builder.addCase(listAssignments.fulfilled, (state: AssignmentsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, listAssignments: false };
      if (action.payload.data.result) {
        assignmentsAdapter.upsertOne(state.assignments, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(listAssignments.rejected, (state: AssignmentsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, listAssignments: false };
    });

    builder.addCase(getJobsiteAssignment.pending, (state: AssignmentsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getJobsiteAssignment: true };
    });
    builder.addCase(getJobsiteAssignment.fulfilled, (state: AssignmentsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getJobsiteAssignment: false };
      if (action.payload.data.result) {
        jobsiteAssignmentAdapter.upsertOne(state.jobsiteAssignment, action.payload.data.result);
      }
    });
    builder.addCase(getJobsiteAssignment.rejected, (state: AssignmentsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getJobsiteAssignment: false };
    });

    builder.addCase(getAssignmentById.pending, (state: AssignmentsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), assignment: true };
    });
    builder.addCase(getAssignmentById.fulfilled, (state: AssignmentsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, assignment: false };
      if (action.payload.data.result) {
        assignmentAdapter.upsertOne(state.assignment, action.payload.data.result);
      }
    });
    builder.addCase(getAssignmentById.rejected, (state: AssignmentsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, assignment: false };
    });

    builder.addCase(getAssignmentKpis.pending, (state: AssignmentsState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), getAssignmentKpis: true };
    });
    builder.addCase(getAssignmentKpis.fulfilled, (state: AssignmentsState, action: AnyAction) => {
      state.fetching = { ...state.fetching, getAssignmentKpis: false };
      state.kpis = action.payload.data.result;
    });
    builder.addCase(getAssignmentKpis.rejected, (state: AssignmentsState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, getAssignmentKpis: false };
    });
  },
});

export const { selectById: selectAssignmentsById } = assignmentsAdapter.getSelectors((state: RootState) => state.assignments.assignments);
export const { selectById: selectJobsiteAssignmentById } = jobsiteAssignmentAdapter.getSelectors((state: RootState) => state.assignments.jobsiteAssignment);
export const { selectById: selectAssignmentById } = assignmentAdapter.getSelectors((state: RootState) => state.assignments.assignment);

export const getJobsiteAssignmentById =
  (siteId = '') =>
  (state: RootState): QueryAssignment | undefined =>
    selectJobsiteAssignmentById(state, siteId);

export const getAssignment =
  (assignmentId = '') =>
  (state: RootState): QueryAssignment | undefined =>
    selectAssignmentById(state, assignmentId);

export const getAssignmentsListSelector = createSelector([selectAssignmentsById], (assignments) => ({
  pagination: getPagination(assignments),
  result: assignments?.result?.map((assignment) => ({
    ...assignment,
    delivered_at_mapped: assignment.delivered_at ? DateTime.fromISO(assignment.delivered_at).toLocaleString(DateTime.DATETIME_SHORT) : '',
  })),
}));

export const getAssignmentsList =
  (keys: ListAssignmentsQuery) =>
  (state: RootState): AssignmentsTypeMapped =>
    getAssignmentsListSelector(state, keysToId(keys));

export const getAssignmentKpisSelector = createSelector([(state: RootState): QueryAssignmentKPIs => state.assignments.kpis], (kpis) => kpis);

export const getJobsiteAssignmentByIdFetching = (state: RootState): boolean => state.assignments.fetching?.getJobsiteAssignment !== false;
export const getAssignmentFetching = (state: RootState): boolean => state.assignments.fetching?.assignment !== false;
export const getAssignmentsFetching = (state: RootState): boolean => state.assignments.fetching?.listAssignments !== false;
export const getAssignmentKpisFetching = (state: RootState): boolean => state.assignments.fetching?.getAssignmentKpis !== false;

export default assignmentsSlice.reducer;
