import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type StatusFilterProps = {
  viewMode?: boolean;
  fullWidth?: boolean;
};

export default function StatusFilter({ viewMode, fullWidth }: StatusFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const status = [
    { name: 'All', id: -1 },
    { name: 'Present', id: 'present' },
    { name: 'In Use', id: 'in use' },
    { name: 'Installed', id: 'installed' },
    { name: 'Left Behind', id: 'left behind' },
    { name: 'JobSite Tool', id: 'jobsite tool' },
    { name: 'Lost', id: 'lost' },
    { name: 'Never Seen', id: 'never seen' },
    { name: 'Not Tracked', id: 'not tracked' },
    { name: 'Missing', id: 'missing' },
  ];

  const currentOption = status.find((option) => option.id === params.trackingStatus);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.name && (
        <Box component="span">
          Owner Type: <strong>{currentOption?.name}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id="trackingStatus">Status</InputLabel>
      <Select
        labelId="trackingStatus"
        defaultValue={!params.trackingStatus || params.trackingStatus === 'All' ? -1 : params.trackingStatus}
        label="Status"
        onChange={(event) => {
          setURLParams({
            newParams: { trackingStatus: event.target.value === -1 ? '' : event.target.value },
          });
        }}
        size="small"
      >
        {status.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
